<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb1rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Database</div>
        <b-button class="MyButton" @click="Unlock">{{ isUnlocked ? 'Lock' : 'Unlock' }}</b-button>
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">{{ UnlockedTimeMsg }}</div>
      </div>
    </div>
    
    
    <b-tabs v-if="isUnlocked" v-model="activeTab" :animated="false">
      <b-tab-item type="is-toggle" expanded label="Query" v-show="isUnlocked" class="columns is-centered is-gapless is-marginless m1rem">
        
        <b-field label="Query" class="column is-7 is-mobile is-gapless is-centered is-multiline">
          <b-input v-model="query" maxlength="20000" type="textarea" custom-class="inputheight1"></b-input>
        </b-field>
        
        <b-field label="Tables" class="column is-4 is-mobile is-gapless is-centered is-multiline">
          <div class="columns is-multiline is-gapless is-marginless">
            <b-input class="column is-12" v-model="tableStructure.search" @input="FilterTableStructureData" message="search"></b-input>
            <b-input class="column is-12" v-model="tableStructure.filtered" maxlength="20000" type="textarea" custom-class="inputheight2"></b-input>
          </div>
        </b-field>
        
        <section class="column is-1 is-mobile is-gapless is-centered is-multiline">
          
          <b-field class="column is-7 is-mobile is-gapless is-centered is-multiline">
            <b-switch size="is-small" v-model="tabelColumnSearchOnly" @input="FilterTableStructureData">tabelColumnSearchOnly</b-switch>
          </b-field>
          
          <b-button class="MyButton" @click="FormatQuery">Format</b-button>
          <b-button class="MyButton" @click="RunQuery">Run</b-button>
          <b-button class="MyButton" @click="DownLoadExcel">Excel</b-button>
          <b-button class="MyButton" @click="DownLoadCSV">CSV</b-button>        
        </section>
      
      </b-tab-item>
      
      
      <b-tab-item type="is-toggle" expanded label="Format" v-show="isUnlocked && activeTab ===1" class="columns is-gapless is-marginless m1rem">
        <b-field label="Format" class="column is-11 is-mobile is-gapless is-centered is-multiline">
          <b-input v-model="resultarea" maxlength="20000" type="textarea" custom-class="inputheight1"></b-input>
        </b-field>
      </b-tab-item>
      
      <b-tab-item type="is-toggle" expanded label="Results" results v-show="isUnlocked && activeTab === 2" class="columns is-centered is-gapless is-marginless m1rem">
        <b-table v-if="table.data.length > 0" class="column is-12 is-mobile is-gapless is-centered is-multiline" style="font-size:11px;"
          :data="table.data" 
          :columns="table.columns"
          :bordered="table.true"
          :striped="table.true"
          :narrowed="table.true"
          :isPaginated="table.true"
          :paginated="table.true"
          per-page="200"
          current-page.sync="table.currentPage"
          >
        </b-table>
      </b-tab-item>
    
    </b-tabs>
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      isUnlocked          : false,
      UnlockedTimeMsg     : '',
      activeTab           : 0,
      
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      tabelColumnSearchOnly: true,
      userstats   : null,
      
      table: {
        true : true,
        currentPage: 1,
        data    : [],
        columns : []
      },
      query : `select * from users limit 10`,
      // query       : `SELECT
      //                     *
      //                 FROM
      //                     messages
      //                 INNER JOIN rooms ON rooms.id = messages.room_id
      //                 WHERE
      //                     rooms.id = 223
      //                 ORDER BY
      //                     messages.created_at
      //                 DESC
      //                 LIMIT 20000`,
      
      tableStructure  : {
        search        : '',
        ori           : '',
        filtered      : '',
      },
      
      dbstructure: {},
      resultarea : '',
      
      LockStatus : {
        unlocked: false,
        Locktime: 0,
      }
    }
  },
  
  async mounted(){
    // this.ShowPageLoading(15000);
    this.UpdateLockStatus();
    // this.GetTableStructureData();
    
    const _this = this;
    setInterval(() => {
      _this.CheckLockStatus();
    }, 1000);
    
  },
  
  methods: {
    
    async UpdateLockStatus() {
      // console.info(`-UpdateLockStatus???`);
      const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
      // console.info(`LockStatus:`, LockStatus);
      this.SetUnlockStatus(LockStatus);
    },
    
    async RunQuery(){
      // this.resultarea = '';
      this.table = {
        true : true,
        currentPage: 1,
        data    : [],
        columns : []
      }
      
      let rtn = await this.$store.dispatch('databaseactions/DB_RunQuery', this.query);
      // console.info(`RunQuery rtn:`, rtn);
      
      const data_headers = this.getDataHeaders(rtn.results.rows);
      // console.info(`data_headers:`, data_headers);
      const data_array = this.getDataArray(rtn.results.rows);      
      // console.info(`data_array:`, data_array);
      
      this.table = {
        true : true,
        currentPage: 1,
        data    : data_array,
        columns : data_headers
      }
      
      this.activeTab = 2;
    },
    
    
    
    getDataArray(data){
      if (data === null || data == false || typeof(data) !== "object" || data.length <=0) {
        return [];
      }
      return data;
    },  
    
    
    getDataHeaders(data){
      if (data === null || data == false || typeof(data) !== "object" || data.length <=0) {
        return [];
      }
      
      let Headers = [];
      for(let key in data[0]) {
        // console.info(`key:`, key);
        Headers.push({
                        field: key,
                        label: key,
                        searchable: true,
                        numeric: typeof(data[0][key]) === "number" ? true : false,
                        centered: true, // typeof(data[0][key]) === "string" ? false : true,
                    })
      }
      
      return Headers;
    },
    
    async FormatQuery(){
      this.resultarea = '';
      
      let rtn = await this.$store.dispatch('databaseactions/DB_FormatQuery', this.query);
      // console.info(`FormatQuery rtn:`, rtn);
      
      this.resultarea = rtn.QueryFormated;
      
      this.resultarea += "\r\n\r\n\r\n### ListItems full ###\n";
      this.resultarea += rtn.ItemList.join("\n");
      
      this.resultarea += "\r\n\r\n\r\n### ListItems simple ###\n";
      this.resultarea += rtn.ItemListSimple.join("\n");
      
      this.activeTab = 1;
    },
    
    
    async GetTableStructureData() {
      this.tableStructure.ori = await this.$store.dispatch('databaseactions/DB_GetDBStructure');
      // console.info(`dbstructure:`, this.tableStructure.ori);
      
      
      this.FilterTableStructureData();
    },
    
    FilterTableStructureData(){
      if (this.tabelColumnSearchOnly) {
        this.FilterTableStructureData_ColumnOnly()
      } else {
        this.FilterTableStructureData_Full()
      }
    },
    FilterTableStructureData_ColumnOnly(){
      let Total = [];
      let ExtraLineChar = '\n';
      const SearchText = this.tableStructure.search.toString().toLowerCase();
      
      for(let tname in this.tableStructure.ori){
        let table = this.tableStructure.ori[tname];
        
        let colomMatch        = this.FindTableText(table, SearchText);
        
        if (colomMatch) {
          let PrintStr = `${tname}\n`;
          // console.info(`table:`, table)
          for(let kkey in table) {
            const col = table[kkey]
            if (col.toLowerCase().indexOf(SearchText) > -1) {
              PrintStr += `        ${table[kkey]}\n`;
            }
          }
          Total.push(PrintStr);
        }
      }      
      
      this.tableStructure.filtered = Total.join(ExtraLineChar);
    },
    FilterTableStructureData_Full(){
      let Total = [];
      let ExtraLineChar = '\n';
      const SearchText = this.tableStructure.search.toString().toLowerCase();
      
      for(let tname in this.tableStructure.ori){
        let table = this.tableStructure.ori[tname];
        
        if (SearchText === '') {
          Total.push(`${tname}\n`);
          ExtraLineChar = '';
          continue;
        }          
          
        let tableSearchMath   = tname.toLowerCase().indexOf(SearchText) > -1;
        let colomMatch        = this.FindTableText(table, SearchText);
        
        if (tableSearchMath || colomMatch) {
          let PrintStr = `${tname}\n`;
          for(let col in table) {
            PrintStr += `        ${table[col]}\n`;
          }
          Total.push(PrintStr);
        }
      }      
      
      this.tableStructure.filtered = Total.join(ExtraLineChar);
    },
    
    FindTableText(tablecoloms, searchText) {
      for(let col of tablecoloms) {
        if (col.toLowerCase().indexOf(searchText) > -1) {
          return true;
        }
      }
      return false;
    },
    
    
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async DownLoadExcel() {
      // create a buffer
      // const buff = Buffer.from(this.query, 'utf-8');
      
      // decode buffer as Base64
      // const base64 = buff.toString('base64');
      const urlEncode = encodeURIComponent(this.query);
      
      // window.open(`${API_ROOT}/monitor/database/DownloadCSV?query=${urlEncode}`)
      window.open(`${API_ROOT}/monitor/database/DownloadExcel?query=${urlEncode}`)
    },
    
    async DownLoadCSV() {
      // create a buffer
      // const buff = Buffer.from(this.query, 'utf-8');
      
      // decode buffer as Base64
      // const base64 = buff.toString('base64');
      const urlEncode = encodeURIComponent(this.query);
            
      //console.info(`${API_ROOT}/monitor/database/DownloadCSV?query=${base64}`)
      // window.open(`${API_ROOT}/monitor/database/DownloadCSV?query=${base64}`)
      window.open(`${API_ROOT}/monitor/database/DownloadCSV?query=${urlEncode}`)
    },
    
    async Unlock() {
      const _this = this;
      
      if (this.isUnlocked) {
        await _this.$store.dispatch('databaseactions/DB_ClearLockStatus', { });
        await _this.UpdateLockStatus();
        return;
      }
      
      this.$buefy.dialog.prompt({
          message: `2FA Code`,
          inputAttrs: {
            placeholder: '2FA Code',
            maxlength: 6
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`2FA Code: ${value}`);
            let rtn = await _this.$store.dispatch('databaseactions/DB_SetLockStatus', { twoFA : value });
            console.info(`rtn:`, rtn);
            
            _this.SetUnlockStatus(rtn);
          }
      })
    },
    
    SetUnlockStatus(LockStatus){
      if (LockStatus === false || LockStatus === null) {
        this.LockStatus = {
          unlocked: false,
          Locktime: 0,
        }
        this.CheckLockStatus();
        return;
      }
      
      this.LockStatus = LockStatus;
      if (LockStatus.unlocked === true) {
        this.GetTableStructureData();
      }
      this.CheckLockStatus();
    },
    
    
    CheckLockStatus(){
      // console.info(`_this.LockStatus:`, this.LockStatus);
      if (this.LockStatus.unlocked === false) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "not unlocked";
        return;
      } 
      
      let curDate = new Date();
      if (parseInt(this.LockStatus.Locktime) < curDate.getTime()) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "expired";
      } else {
        this.isUnlocked      = true;
        this.UnlockedTimeMsg = Calc_TimeLeftMsg(parseInt(this.LockStatus.Locktime));
      }
    }
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }

</style>